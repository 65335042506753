import React, { createRef, useEffect, useState } from 'react';
import Heading from '../../../CommonComponents/Heading/Heading';
import Description from '../../../CommonComponents/Description/Description';
import Button from '../../../CommonComponents/Button/Button';
import ImageSlider from '../../../CommonComponents/ImageSlider/ImageSlider';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';

import {
  MiddleBodyParent,
  BannerContainer,
  MiddleBodyVideoBackground,
  TopContainer,
  BottomContainer,
  TopLeftSection,
  TopRightSection,
  ButtonContainer,
  NavContainer,
  NavContent,
} from './ModernMiddleBody3.styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernMiddleBody3(props) {
  const [middleBodyState, setMiddleBodyState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [activeLink, setActiveLink] = useState({
    navLink: middleBodyState?.navContent?.content
      ? middleBodyState?.navContent?.content[0]?.navText
      : '',
    navContent: middleBodyState?.navContent?.content
      ? middleBodyState?.navContent?.content[0]?.content
      : {},
    navId: middleBodyState?.navContent?.content
      ? middleBodyState?.navContent?.content[0]?.id
      : '',
  });
  const linkRefs = Array.from({
    length: middleBodyState?.navContent?.content?.length,
  }).map(() => createRef(null));

  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setMiddleBodyState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const dispatch = useDispatch();
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };

  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.setAttribute('playsinline', '');
    }
  }, []);
  // const handleNavClick = (navText,navContent) => setActiveLink((prevLink)=>(navText.length&&navContent?({navLink:navText,navContent:navContent}):{...prevLink}));
  const handleNavClick = (navText, navContent, index, navId) => {
    linkRefs[index].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setActiveLink({
      navLink: navText.length && navContent ? navText : activeLink.navLink,
      navContent: navContent ? navContent : activeLink.navContent,
      navId: navId ? navId : activeLink?.navId,
    });
  };

  const renderParentContainer = (bgType) => {
    return (
      <>
        {
          <TopContainer>
            {(middleBodyState?.heading?.text || middleBodyState?.description?.text) && (
              <TopLeftSection textStyles={propStyle}>
                {middleBodyState?.heading?.text && (
                  <Heading headingData={middleBodyState?.heading} />
                )}
                {middleBodyState?.description?.text && (
                  <Description descriptionData={middleBodyState?.description} />
                )}
              </TopLeftSection>
            )}
            {middleBodyState?.button?.content && (
              <TopRightSection>
                <ButtonContainer>
                  {middleBodyState?.button?.content?.map((btnData, idx) => (
                    <Button
                      key={btnData?.id || idx}
                      type={middleBodyState?.button?.type}
                      btnData={btnData}
                      primaryButton={propStyle?.buttonPrimary}
                      secondaryButton={propStyle?.buttonSecondary}
                      callback={openForm}
                    />
                  ))}
                </ButtonContainer>
              </TopRightSection>
            )}
          </TopContainer>
        }
        {middleBodyState?.navContent?.content?.length > 0 && (
          <BottomContainer className="tab-bottom">
            {middleBodyState?.navContent?.content?.length > 0 ? (
              <NavContainer
                textStyles={propStyle}
                isNavItem={
                  middleBodyState?.navContent?.content?.length > 0 ? true : false
                }
              >
                {middleBodyState?.navContent?.content?.length > 0 ? (
                  <div className="nav-links">
                    {middleBodyState?.navContent?.content.map((navData, idx) =>
                      navData?.navText?.length > 0 ? (
                        <div
                          className="nav-link-text"
                          key={navData?.id}
                          onClick={() =>
                            handleNavClick(
                              navData?.navText,
                              navData?.content,
                              idx,
                              navData?.id
                            )
                          }
                          ref={linkRefs[idx]}
                        >
                          {navData?.navText}{' '}
                          <div
                            className={`bar ${
                              activeLink?.navId === navData?.id ? 'active' : 'hidden'
                            }`}
                          ></div>
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                ) : null}
              </NavContainer>
            ) : null}
            {
              <NavContent className="nav-content" propStyle={propStyle}>
                <ImageSlider
                  mediaContent={
                    transformWidgetProps(activeLink?.navContent?.children)?.media
                      ?.foreground || []
                  }
                  key={activeLink?.navLink}
                  hideDots={'hide'}
                  usedIn="tab3"
                />
              </NavContent>
            }
          </BottomContainer>
        )}
      </>
    );
  };
  return middleBodyState?.background?.type === 'video' ? (
    <MiddleBodyVideoBackground
      id="MT__MIDDLEBODY3"
      styleConfig={styleConfig?.config}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebodytab3 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {' '}
      <video
        autoPlay
        loop
        muted
        onError={(e) =>
          (e.target.src = middleBodyState?.media?.background?.content[0]?.url)
        }
        playsInline
      >
        <source
          src={middleBodyState?.media?.background?.content[0]?.url}
          type="video/mp4"
        />
      </video>{' '}
      <BannerContainer> {renderParentContainer('video')} </BannerContainer>
    </MiddleBodyVideoBackground>
  ) : (
    <MiddleBodyParent
      id="MT__MIDDLEBODY3"
      styleConfig={styleConfig?.config}
      propsBackground={middleBodyState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebodytab3 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      <BannerContainer> {renderParentContainer('')}</BannerContainer>
    </MiddleBodyParent>
  );
}

export default ModernMiddleBody3;
